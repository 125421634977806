export const PRODUCT_IMAGE_SITE_TYPE_TREND = 'trend'

export const PRODUCT_IMAGE_SITE_TYPE_DEFAULT = PRODUCT_IMAGE_SITE_TYPE_TREND

export default {
  computed: {
    productImageSiteTypeValues () {
      return [
        {
          id: PRODUCT_IMAGE_SITE_TYPE_TREND,
          title: this.$t('eshopSubscriptionProduct.productImageSiteType.trend')
        }
      ]
    }
  }
}
