export const PRODUCT_IMAGE_TYPE_STATIC = 'static'
export const PRODUCT_IMAGE_TYPE_DYNAMIC = 'dynamic'

export const PRODUCT_IMAGE_TYPE_DEFAULT = PRODUCT_IMAGE_TYPE_STATIC

export default {
  computed: {
    productImageTypeValues () {
      return [
        {
          id: PRODUCT_IMAGE_TYPE_STATIC,
          title: this.$t('eshopSubscriptionProduct.productImageType.static')
        },
        {
          id: PRODUCT_IMAGE_TYPE_DYNAMIC,
          title: this.$t('eshopSubscriptionProduct.productImageType.dynamic')
        }
      ]
    }
  }
}
