<script>
import SubscriptionProductNew from './SubscriptionProductNew'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'SubscriptionProductEdit',
  extends: SubscriptionProductNew,
  computed: {
    productId () {
      return Number(this.$route.params.id)
    },
    relatedProducts () {
      return this.$store.getters['eshopSubscriptionProduct/all'].filter(product => {
        return this.productId !== product.id
      })
    }
  },
  methods: {
    initFields () {
      this.subscriptionProduct.relatedProducts = this.subscriptionProduct.relatedProducts.map(productId => {
        return this.relatedProducts.find(product => productId === product.id)
      })
      this.subscriptionProduct.digitalServices = this.subscriptionProduct.digitalServices.map(digitalServiceId => {
        return this.digitalServices.find(digitalService => digitalServiceId === digitalService.id)
      })
    },
    getSubscriptionProduct () {
      this.$store.dispatch('eshopSubscriptionProduct/fetchOne', this.productId)
        .then(() => {
          this.subscriptionProduct = this.$store.getters['eshopSubscriptionProduct/detail']
          this.initFields()
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('eshopSubscriptionProduct.error.required_fields'))
        return
      }
      this.$store.dispatch('eshopSubscriptionProduct/update', this.prepareSubscriptionProductRequest(this.subscriptionProduct))
        .then(() => {
          if (this.$store.getters['eshopSubscriptionProduct/error'] === null) {
            this.getSubscriptionProduct()
            NotifyService.setSuccessMessage(this.$t('eshopSubscriptionProduct.message.updated_record'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['eshopSubscriptionProduct/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getSubscriptionProduct()
  }
}
</script>
